import { Fragment, useState } from "react";
import toast, { Toaster }  from "react-hot-toast";
import axios from "axios";
const SideBar = () => {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handleSubmitSecond = async () => {
 
    setLoading(true)
    try {
      const sendEmail = await axios.post('/api/send-email', {
        subject: "Query Form From Website",
        toEmail: 'ishariqriaz@gmail.com',
        text: form.name,
        html_text: `
        <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
          <h2 style="color: #333333;">Hello , ${form.name} </h2>
          <p style="color: #333333;">Thank you for your purchase!</p>
          <p style="color: #333333;">Order Details:</p>
          <ul style="list-style-type: none; padding: 0;">

            <li style="color: #333333;">Customer Contact information:  </li>
            <li style="color: #333333;">Customer Name:  ${form.name} </li>
            <li style="color: #333333;">Customer Phone:  ${form.phone} </li>
            <li style="color: #333333;">Customer Email:  ${form.email} </li>
            <li style="color: #333333;">Customer Message:  ${form.message} </li>


          </ul>
          <p style="color: #333333;">If you have any questions, feel free to reply to this email.</p>
          <p style="color: #333333;">Thank you,</p>
          <p style="color: #333333;">Techcelerant</p>
        </div>
      `,
      });
      if(sendEmail.data === 'Success'){
        setLoading(false);
        
      }
      else {
        toast.error("Please Retry again.");
        setLoading(false)
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();  
    setLoading(true)
    try {
      const sendEmail = await axios.post('/api/send-email', {
        subject: "Query Form From Website",
        toEmail: 'dev.techriffic@gmail.com',
        text: form.name,
        html_text: `
        <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
          <h2 style="color: #333333;">Hello , ${form.name} </h2>
          <p style="color: #333333;">Thank you for your purchase!</p>
          <p style="color: #333333;">Order Details:</p>
          <ul style="list-style-type: none; padding: 0;">

            <li style="color: #333333;">Customer Contact information:  </li>
            <li style="color: #333333;">Customer Name:  ${form.name} </li>
            <li style="color: #333333;">Customer Phone:  ${form.phone} </li>
            <li style="color: #333333;">Customer Email:  ${form.email} </li>
            <li style="color: #333333;">Customer Message:  ${form.message} </li>


          </ul>
          <p style="color: #333333;">If you have any questions, feel free to reply to this email.</p>
          <p style="color: #333333;">Thank you,</p>
          <p style="color: #333333;">Techcelerant</p>
        </div>
      `,
      });
      if(sendEmail.data === 'Success'){
        toast.success("Thank you!, Our representative will contact You.");
        setLoading(false);
        handleSubmitSecond();

      }
      else {
        toast.error("Please Retry again.");
        setLoading(false);
        handleSubmitSecond();
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false)
    }
  };


  return (
    <Fragment>
      <Toaster/>
      <div className="form-back-drop"></div>
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon">
            <span className="fa fa-times" />
          </div>
          <div className="title">
            <h4>Get Appointment</h4>
          </div>
          {/*Appointment Form*/}
          <div className="appointment-form">
            <form
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <input type="text" name="name" placeholder="Name" value={form.name}
                onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="phone"
                  name="phone"
                  placeholder="phone"
                  onChange={handleChange}
                  required
                  value={form.phone}
                />
              </div>
              <div className="form-group">
                <textarea  onChange={handleChange}   value={form.phone}   required placeholder="Message" rows={5} />
              </div>
              <div className="form-group">
                <button type="submit" className="theme-btn">
                {loading ? 'Submitting...' : 'Submit now' }  
                </button>
              </div>
            </form>
          </div>
          {/*Social Icons*/}
          <div className="social-style-one">
            <a href="#">
              <i className="fab fa-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#">
              <i className="fab fa-instagram" />
            </a>
            <a href="#">
              <i className="fab fa-pinterest-p" />
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default SideBar;
